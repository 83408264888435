import React, { FC, useEffect, useState } from "react";
import { navigate } from "gatsby";
import { Area, AreaChart, XAxis, YAxis } from "recharts";
import { LoadingIndicator } from "../LoadingIndicator";
import { Card } from "../Card";
import { isAuthenticated } from "../../auth";
import { query } from "../../api";
import { IUser } from "../../types/user";
import { ISubscription } from "../../types/subscription";
import { IPlan } from "../../types/plan";


function inDateRange(dateRange: { start: Date; end: Date }) {
  return (obj: { start: Date; end: Date }): boolean => {
    return dateRange.start <= obj.start && obj.end <= dateRange.end;
  };
}

interface IActivity {
  duration: number;
  start: Date;
  end: Date;
}

export const Metrics: FC = () => {
  const [streamActivity, setStreamActivity] = useState<Array<IActivity>>([]);
  const [users, setUsers] = useState<Array<IUser>>([]);
  const [subscriptions, setSubscriptions] = useState<Array<ISubscription>>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate("/login");
      return;
    }

    query(`
      {
        activity {
          start
          end
        }
        users {
          createdAt
        }
        plans {
          id
          subscriptions(cancelled: false) {
            createdAt
            customer {
              id
            }
          }
        }
      }
    `).then(({ users, activity, plans }: { users: Array<IUser>; activity: any; plans: Array<IPlan> }) => {
      setStreamActivity(
        activity.map((act: any) => {
          const start = new Date(parseInt(act.start));
          const end = new Date(parseInt(act.end));
          const duration = end.getTime() - start.getTime();
          return {
            duration,
            start,
            end,
          };
        }),
      );
      setUsers(users);
      setSubscriptions(plans.filter((p: IPlan) => p.id === "5ad0583836883822318fa270")[0].subscriptions);
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  const today = new Date();
  const months = [
    "januar",
    "februar",
    "marts",
    "april",
    "maj",
    "juni",
    "juli",
    "august",
    "september",
    "oktober",
    "november",
    "december",
  ];

  const durationActivity = [];
  const sessionActivity = [];
  const userSignups = [];
  for (let i = -3; i <= 0; i++) {
    const startDate = new Date(Date.UTC(today.getFullYear(), today.getMonth() + i, 1));
    const endDate = new Date(Date.UTC(today.getFullYear(), today.getMonth() + i + 1, 1));
    const dateRange = {
      start: startDate,
      end: endDate,
    };
    const activity = streamActivity.filter(inDateRange(dateRange));

    const summedDuration = activity
      .map(userActivity => {
        return userActivity.duration / 1000;
      })
      .reduce((sum, dur) => sum + dur, 0);

    durationActivity.push({
      dateRange,
      dateString: `${months[startDate.getMonth()]}`,
      duration: summedDuration / (activity.length || 1),
    });

    sessionActivity.push({
      dateRange,
      dateString: `${months[startDate.getMonth()]}`,
      sessions: activity.length,
    });

    const filteredUsers = users.filter((user: IUser) => {
      if (!user.createdAt) {
        return true;
      }
      return new Date(parseInt(user.createdAt)) < endDate;
    });

    userSignups.push({
      dateRange,
      dateString: `${months[startDate.getMonth()]}`,
      users: filteredUsers.length,
    });
  }

  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      <Card style={{ marginRight: "20px" }}>
        <h2>Gennemsnitlig sessionsvarighed</h2>
        <AreaChart width={600} height={300} data={durationActivity} style={{ margin: "20px" }}>
          <defs>
            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#38a5ff" stopOpacity={0.9} />
              <stop offset="95%" stopColor="#38a5ff" stopOpacity={0.1} />
            </linearGradient>
          </defs>
          <Area type="monotone" dataKey="duration" stroke="#38a5ff" fillOpacity={1} fill="url(#colorPv)" />
          <XAxis dataKey="dateString" stroke="#eef" />
          <YAxis dataKey="duration" stroke="#eef" unit="s" />
        </AreaChart>
      </Card>
      <Card style={{ marginRight: "20px" }}>
        <h2>Antal sessioner</h2>
        <AreaChart width={600} height={300} data={sessionActivity} style={{ margin: "20px" }}>
          <defs>
            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#38a5ff" stopOpacity={0.9} />
              <stop offset="95%" stopColor="#38a5ff" stopOpacity={0.1} />
            </linearGradient>
          </defs>
          <Area type="monotone" dataKey="sessions" stroke="#38a5ff" fillOpacity={1} fill="url(#colorPv)" />
          <XAxis dataKey="dateString" stroke="#eef" />
          <YAxis dataKey="sessions" stroke="#eef" />
        </AreaChart>
      </Card>
      <Card style={{ marginRight: "20px" }}>
        <h2>Antal brugere</h2>
        <AreaChart width={600} height={300} data={userSignups} style={{ margin: "20px" }}>
          <defs>
            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#38a5ff" stopOpacity={0.9} />
              <stop offset="95%" stopColor="#38a5ff" stopOpacity={0.1} />
            </linearGradient>
          </defs>
          <Area type="monotone" dataKey="users" stroke="#38a5ff" fillOpacity={1} fill="url(#colorPv)" />
          <XAxis dataKey="dateString" stroke="#eef" />
          <YAxis dataKey="users" stroke="#eef" />
        </AreaChart>
      </Card>
      <Card
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          marginRight: "20px",
        }}
      >
        <h2>Antal brugere i alt</h2>
        <h1 style={{ textAlign: "center", alignSelf: "center" }}>{users.length}</h1>
      </Card>
      <Card
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          marginRight: "20px",
        }}
      >
        <h2>Antal sessions i alt</h2>
        <h1 style={{ textAlign: "center", alignSelf: "center" }}>{streamActivity.length}</h1>
      </Card>
      <Card
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          marginRight: "20px",
        }}
      >
        <h2>Antal timer streamet i alt</h2>
        <h1 style={{ textAlign: "center", alignSelf: "center" }}>
          {(
            streamActivity
              .map((s: IActivity) => {
                return new Date(s.end).getTime() - new Date(s.start).getTime();
              })
              .reduce((acc, time) => {
                return acc + time;
              }, 0)
            / (1000 * 60 * 60)
          ).toFixed(2)}
        </h1>
      </Card>
      <Card
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          marginRight: "20px",
        }}
      >
        <h2>Antal subscriptions</h2>
        <h1 style={{ textAlign: "center", alignSelf: "center" }}>{subscriptions.length}</h1>
      </Card>
    </div>
  );
};
